import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import KnowledgebasesAPI from '../../api/knowledgebases';
import { throwErrorMessage } from '../utils/api';

export const state = {
  records: [],
  selectedKnowledgebase: null,
  activeSchema: null,
  uiFlags: {
    isFetchingItem: false,
    isFetching: false,
    isCreating: false,
    isDeleting: false,
    isUpdating: false,
    isExecuting: false,
  },
};

export const getters = {
  getKnowledgebases($state) {
    return $state.records;
  },
  getKnowledgebase: $state => id => {
    return $state.records.find(record => record.id === Number(id));
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getSelectedKnowledgebase($state) {
    return $state.selectedKnowledgebase;
  },
  getActiveSchema($state) {
    return $state.activeSchema;
  },
};

export const actions = {
  get: async function getKnowledgebases({ commit }) {
    commit(types.SET_KNOWLEDGEBASES_UI_FLAG, { isFetching: true });
    try {
      const response = await KnowledgebasesAPI.get();
      commit(types.SET_KNOWLEDGEBASES, response.data || []);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_KNOWLEDGEBASES_UI_FLAG, { isFetching: false });
    }
  },
  getSingleKnowledgebase: async function getKnowledgebaseById({ commit }, knowledgebaseId) {
    commit(types.SET_KNOWLEDGEBASES_UI_FLAG, { isFetchingItem: true });
    try {
      const response = await KnowledgebasesAPI.show(knowledgebaseId);
      commit(types.ADD_KNOWLEDGEBASE, response.data);
      commit(types.SET_SELECTED_KNOWLEDGEBASE, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_KNOWLEDGEBASES_UI_FLAG, { isFetchingItem: false });
    }
  },
  create: async function createKnowledgebase({ commit }, knowledgebaseObj) {
    commit(types.SET_KNOWLEDGEBASES_UI_FLAG, { isCreating: true });
    try {
      const response = await KnowledgebasesAPI.create(knowledgebaseObj);
      commit(types.ADD_KNOWLEDGEBASE, response.data);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_KNOWLEDGEBASES_UI_FLAG, { isCreating: false });
    }
  },
  addFile: async ({ commit }, { id, file, files }) => {
    commit(types.SET_KNOWLEDGEBASES_UI_FLAG, { isUpdating: true });
    try {
      const response = await KnowledgebasesAPI.addFile(id, file, files);
      commit(types.EDIT_KNOWLEDGEBASE_ADD_FILE, response.data);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_KNOWLEDGEBASES_UI_FLAG, { isUpdating: false });
    }
  },
  removeFiles: async ({ commit }, { id, toKeep }) => {
    commit(types.SET_KNOWLEDGEBASES_UI_FLAG, { isUpdating: true });
    try {
      const response = await KnowledgebasesAPI.removeFiles(id, toKeep);
      commit(types.EDIT_KNOWLEDGEBASE_ADD_FILE, response.data);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_KNOWLEDGEBASES_UI_FLAG, { isUpdating: false });
    }
  },
  update: async ({ commit }, { id, ...updateObj }) => {
    commit(types.SET_KNOWLEDGEBASES_UI_FLAG, { isUpdating: true });
    try {
      const response = await KnowledgebasesAPI.update(id, {knowledgebase :updateObj});
      commit(types.EDIT_KNOWLEDGEBASE, response.data);
    } catch (error) {
      console.error(error)
      throwErrorMessage(error);
    } finally {
      commit(types.SET_KNOWLEDGEBASES_UI_FLAG, { isUpdating: false });
    }
  },
  delete: async ({ commit }, id) => {
    commit(types.SET_KNOWLEDGEBASES_UI_FLAG, { isDeleting: true });
    try {
      await KnowledgebasesAPI.delete(id);
      commit(types.DELETE_KNOWLEDGEBASE, id);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_KNOWLEDGEBASES_UI_FLAG, { isDeleting: false });
    }
  },
  fetchActiveSchema: async ({ commit }, id) => {
    commit(types.SET_KNOWLEDGEBASES_UI_FLAG, { isFetching: true });
    try {
      const response = await KnowledgebasesAPI.getSchema(id);
      commit(types.SET_ACTIVE_SCHEMA, response.data.schema);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_KNOWLEDGEBASES_UI_FLAG, { isFetching: false });
    }
  },
  updateActiveSchema: async ({ commit }, {id , schema}) => {
    commit(types.SET_KNOWLEDGEBASES_UI_FLAG, { isUpdating: true });
    try {
      const response = await KnowledgebasesAPI.updateSchema(id, schema);
      commit(types.SET_ACTIVE_SCHEMA, schema);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_KNOWLEDGEBASES_UI_FLAG, { isUpdating: false });
    }
  },
};

export const mutations = {
  [types.SET_KNOWLEDGEBASES_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
  [types.SET_SELECTED_KNOWLEDGEBASE]($state, knowledgebase) {
    $state.selectedKnowledgebase = knowledgebase;
  },
  [types.EDIT_KNOWLEDGEBASE_ADD_FILE]($state, knowledgebase) {
    $state.selectedKnowledgebase.files = knowledgebase.files
  },
  [types.ADD_KNOWLEDGEBASE]: MutationHelpers.setSingleRecord,
  [types.SET_KNOWLEDGEBASES]: MutationHelpers.set,
  [types.EDIT_KNOWLEDGEBASE]($state, knowledgebase) {
    console.log(knowledgebase)
    console.log($state.records)
    $state.records = [
      ...$state.records.filter(record => record.id !== knowledgebase.id),
      knowledgebase
    ]
    if ($state.selectedKnowledgebase.id === knowledgebase.id) {
      $state.selectedKnowledgebase = knowledgebase;
    }
  },
  [types.DELETE_KNOWLEDGEBASE]: MutationHelpers.destroy,
  [types.SET_ACTIVE_SCHEMA]($state, schema) {
    $state.activeSchema = schema;
  },
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};