import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import { throwErrorMessage } from '../utils/api';
import AssistantsApi from '../../api/assistants';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isFetchingItem: false,
    isCreating: false,
    isDeleting: false,
    isUpdating: false,
    isFetchingAssistants: false,
    isSettingAssistant: false,
    isDisconnecting: false,
    isGeneratingAvatar: false,
  },
};

export const getters = {
  getAssistants($state) {
    return $state.records;
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getAssistant: $state => assistantId => {
    const [bot] = $state.records.filter(
      record => record.id === Number(assistantId)
    );
    return bot || {};
  },
  getActiveAssistant: $state => inboxId => {
    const [bot] = $state.records.filter(
      record => record.inbox_id === Number(inboxId)
    );

    return bot || {};
  },
};

export const actions = {
  get: async ({ commit }) => {
    commit(types.SET_ASSISTANT_UI_FLAG, { isFetching: true });
    try {
      const response = await AssistantsApi.get();
      commit(types.SET_ASSISTANTS, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_ASSISTANT_UI_FLAG, { isFetching: false });
    }
  },
  generate: async ({ commit }, agentBotObj) => {
    commit(types.SET_ASSISTANT_UI_FLAG, { isCreating: true });
    try {
      const response = await AssistantsApi.generate(agentBotObj);
      commit(types.ADD_ASSISTANT, response.data);
      return response.data;
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_ASSISTANT_UI_FLAG, { isCreating: false });
    }
    return null;
  },
  update: async ({ commit }, { id, ...agentBotObj }) => {
    commit(types.SET_ASSISTANT_UI_FLAG, { isUpdating: true });
    try {
      const response = await AssistantsApi.update(id, agentBotObj);
      commit(types.EDIT_ASSISTANT, response.data);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_ASSISTANT_UI_FLAG, { isUpdating: false });
    }
  },
  delete: async ({ commit }, id) => {
    commit(types.SET_ASSISTANT_UI_FLAG, { isDeleting: true });
    try {
      await AssistantsApi.delete(id);
      commit(types.DELETE_ASSISTANT, id);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_ASSISTANT_UI_FLAG, { isDeleting: false });
    }
  },
  show: async ({ commit }, id) => {
    commit(types.SET_ASSISTANT_UI_FLAG, { isFetchingItem: true });
    try {
      const { data } = await AssistantsApi.show(id);
      commit(types.ADD_ASSISTANT, data);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_ASSISTANT_UI_FLAG, { isFetchingItem: false });
    }
  },
  generateAvatar: async ({ commit }, { description }) => {
    commit(types.SET_ASSISTANT_UI_FLAG, { isGeneratingAvatar: true });
    try {
      const { data } = await AssistantsApi.avatar(description);
      return data.url;
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_ASSISTANT_UI_FLAG, { isGeneratingAvatar: false });
    }
    return null;
  },
};

export const mutations = {
  [types.SET_ASSISTANT_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
  [types.SET_ASSISTANTS]: MutationHelpers.set,
  [types.ADD_ASSISTANT]: MutationHelpers.setSingleRecord,
  [types.EDIT_ASSISTANT]: MutationHelpers.update,
  [types.DELETE_ASSISTANT]: MutationHelpers.destroy,
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
