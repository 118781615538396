import { frontendURL } from '../../../../helper/URLHelper';
const SettingsContent = () => import('../Wrapper.vue');
const Index = () => import('./Index.vue');
const Content = () => import('./Content.vue');
const Schema = () => import('./Schema.vue');

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/knowledgebases'),
      component: SettingsContent,
      props: {
        headerTitle: 'KNOWLEDGEBASES.HEADER',
        icon: 'book',
      },
      children: [
        {
          path: '',
          name: 'settings_knowledgebases_index',
          roles: ['administrator'],
          component: Index,
        },
        {
          path: ':knowledgebaseId',
          name: 'settings_knowledgebases_show',
          component: Content,
          roles: ['administrator'],
        },
        {
          path: ':knowledgebaseId/schema',
          name: 'settings_knowledgebases_schema',
          component: Schema,
          roles: ['administrator'],
        },
      ],
    },
  ],
};
