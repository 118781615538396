import CannedResponse from "../../../../api/cannedResponse";

const personalizeCannedResponse = async (cannedResponseId, chat) => {
    const data = {
        "conversation_id": chat.id, 
    }
    const response = await CannedResponse.personalize(cannedResponseId, { data });
    return response.data;
}

export {
    personalizeCannedResponse
}

