/* global axios */

import ApiClient from './ApiClient';

class CannedResponse extends ApiClient {
  constructor() {
    super('canned_responses', { accountScoped: true });
  }

  get({ searchKey }) {
    const url = searchKey ? `${this.url}?search=${searchKey}` : this.url;
    return axios.get(url);
  }

  personalize(id, { data }) {
    return axios.post(`${this.url}/${id}/personalize`, { ...data });
  }
}

export default new CannedResponse();
